/* Slider */
.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

@charset 'UTF-8';
/* Slider */
.slick-loading .slick-list
{
    background: #fff url(/_next/static/media/ajax-loader.0b80f665.gif) center center no-repeat;
}

/* Icons */
@font-face
{
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;

    src: url(/_next/static/media/slick.25572f22.eot);
    src: url(/_next/static/media/slick.25572f22.eot?#iefix) format('embedded-opentype'), url(/_next/static/media/slick.653a4cbb.woff) format('woff'), url(/_next/static/media/slick.6aa1ee46.ttf) format('truetype'), url(/_next/static/media/slick.f895cfdf.svg#slick) format('svg');
}
/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: .75;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{
    left: -25px;
}
[dir='rtl'] .slick-prev
{
    right: -25px;
    left: auto;
}
.slick-prev:before
{
    content: '←';
}
[dir='rtl'] .slick-prev:before
{
    content: '→';
}

.slick-next
{
    right: -25px;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: -25px;
}
.slick-next:before
{
    content: '→';
}
[dir='rtl'] .slick-next:before
{
    content: '←';
}

/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    opacity: .75;
    color: black;
}

@font-face {
font-family: '__saol_40b425';
src: url(/_next/static/media/a63f776f9c0a0ec4-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__saol_40b425';
src: url(/_next/static/media/53119eeb5d808e5d-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__saol_40b425';
src: url(/_next/static/media/f349898cee58fa09-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__saol_Fallback_40b425';src: local("Arial");ascent-override: 77.40%;descent-override: 25.80%;line-gap-override: 0.00%;size-adjust: 96.90%
}.__className_40b425 {font-family: '__saol_40b425', '__saol_Fallback_40b425'
}.__variable_40b425 {--saol-text: '__saol_40b425', '__saol_Fallback_40b425'
}

@font-face {
font-family: '__graphikCompact_75b0c8';
src: url(/_next/static/media/ca1c7ba84c002e81-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__graphikCompact_75b0c8';
src: url(/_next/static/media/dfa93b9e43b68220-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__graphikCompact_75b0c8';
src: url(/_next/static/media/1b1fe1d93189867c-s.p.woff2) format('woff2');
font-display: swap;
font-style: normal;
}@font-face {font-family: '__graphikCompact_Fallback_75b0c8';src: local("Arial");ascent-override: 108.26%;descent-override: 21.52%;line-gap-override: 0.00%;size-adjust: 90.62%
}.__className_75b0c8 {font-family: '__graphikCompact_75b0c8', '__graphikCompact_Fallback_75b0c8'
}.__variable_75b0c8 {--graphik-compact: '__graphikCompact_75b0c8', '__graphikCompact_Fallback_75b0c8'
}

@font-face {
font-family: '__graphikCond_cdc833';
src: url(/_next/static/media/b0f60f2bca7964f3-s.p.woff2) format('woff2');
font-display: swap;
font-style: normal;
}

@font-face {
font-family: '__graphikCond_cdc833';
src: url(/_next/static/media/b0f60f2bca7964f3-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__graphikCond_cdc833';
src: url(/_next/static/media/3de697d86e4445fe-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__graphikCond_cdc833';
src: url(/_next/static/media/c8771b905e084254-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
}

@font-face {
font-family: '__graphikCond_cdc833';
src: url(/_next/static/media/3a9e61bb4610dfff-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
}@font-face {font-family: '__graphikCond_Fallback_cdc833';src: local("Arial");ascent-override: 127.61%;descent-override: 25.37%;line-gap-override: 0.00%;size-adjust: 76.88%
}.__className_cdc833 {font-family: '__graphikCond_cdc833', '__graphikCond_Fallback_cdc833'
}.__variable_cdc833 {--graphik-cond: '__graphikCond_cdc833', '__graphikCond_Fallback_cdc833'
}

#teconsent {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

@media screen and (max-width: 768px) {
  #teconsent {
    padding-bottom: 70px;
  }
}

button {
  color: #111111;
}

ul[class^='nativo-well-'] {
  list-style: none;
  display: block !important;
}

div.nativo-rail div.ntv-rail ul {
  row-gap: 13px !important;
  column-gap: 13px !important;
}

div.ntv-rail ul {
  list-style: none !important;
  flex-flow: row !important;
  column-gap: 10px !important;
  row-gap: 10px !important;
}

@media screen and (max-width: 1024px) {
  div.ntv-rail ul {
    flex-flow: row wrap !important;
  }
}

div.ntv-rail ul li:not(:last-of-type) {
  margin-right: 0 !important;
}

div.ntv-rail ul li:last-of-type {
  margin-right: 25px !important;
}

div.nativo-rail {
  grid-column: span 2;
}

@media screen and (min-width: 1024px) {
  div.nativo-rail {
    grid-column: span 3;
  }
}

div.nativo-rail ul {
  list-style: none !important;
}

@media screen and (max-width: 1152px) {
  div.nativo-right-tout {
    display: none;
  }
}

@media screen and (min-width: 769px) {
  .nativo-section:empty {
    display: none;
  }

  ul .nativo-section  {
    margin: 0 !important;
  }
}

@media screen and (max-width: 768px) {
  ul:has(li.nativo-section:empty) {  
     height: calc(100vw * 0.88) !important;
  }
}

div#article-related.latest-popular-module {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

#article-related .latest-popular-content {
    display: grid;
    grid-auto-flow: row;
    grid-auto-columns: 1fr;
    grid-gap: 40px;
    gap: 40px;
}

@media (min-width: 768px) {
    div#article-related.latest-popular-module {
        gap: 48px;
    }

    #article-related .latest-popular-content {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 48px;
    }
}

#article-related .heading {
    display: flex;
    align-items: center;
}

#article-related .heading::before, #article-related .heading::after {
    content: '';
    flex-grow: 1;
    height: 0;
    background-color: #f2f2f2;
}

@media (min-width: 768px) {
    #article-related .heading::before, #article-related .heading::after {
      height: 1px;
    }

    #article-related .heading::before {
      margin-right: 56px;
    }
  
    #article-related .heading::after {
      margin-left: 56px;
    }
}

@media (min-width: 1440px) {
    #article-related .heading::before {
      margin-right: 104px;
    }
  
    #article-related .heading::after {
      margin-left: 104px;
    }
}

#article-related .latest-popular-title {
    letter-spacing: -1.5px;
    text-decoration: underline;
    text-underline-offset: 8px;
    -webkit-text-decoration-color: #E31E23;
            text-decoration-color: #E31E23;
    text-decoration-thickness: 4px;
    font-weight: 600;
    line-height: 120%;
    font-family: '__saol_40b425', "Times New Roman", "Playfair Display", Georgia, serif;
    letter-spacing: 0px;
    font-size: 2rem;
}

#article-related .link-wrapper > a {
    font-family: '__graphikCond_cdc833';
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 24px;
    text-transform: uppercase;
    position: relative;
    z-index: 2; 
    text-decoration: none;
    color: #E4121A;
}

#article-related .card {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

#article-related .title-link-wrapper > a {
    font-family: '__saol_40b425';
    font-size: 24px;
    font-weight: 600;
    text-align: left;
    text-decoration: none !important;
    line-height: 28px;
    display: block;
    color: rgb(17, 17, 17);
}

#article-related .title-link-wrapper > a:hover {
    color: rgb(0, 123, 157);
    text-decoration: none;
}

#article-related .date {
    color: #666666;
    font-family: '__graphikCond_cdc833';
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 1.17px;
    line-height: 21px;
    min-height: 21px;
    position: relative;
    z-index: 2;
}

#article-related .author-list{
    font-size: 14px;
    letter-spacing: 1.2px;
    line-height: 21px;
    margin: 0px;
    text-transform: uppercase;
}

#article-related .by-text {
    font-family: '__graphikCond_cdc833';
    font-weight: inherit;
    color: #111111;
    margin: 0px 4px 0px 0px;
}

#article-related .list-item-wrapper {
    font-family: '__graphikCond_cdc833';
    font-weight: 600; 
}


/* ------ CSS VARIABLES >> ------ */
:root {
    --Section-Margin: 3rem; /*48px*/
    --Grid-Gap: 1rem; /*16px*/
    --Grid-Margin: 1.25rem; /*20px*/
    --Footer-Margin: 5rem; /*80px*/
}

/*md*/
@media (min-width: 768px) {
    :root {
        --Grid-Gap: 1.5rem; /*24px*/
        --Grid-Margin: 2rem; /*32px*/
    }
}

/*lg*/  
@media (min-width: 1024px) {
    :root {
        --Section-Margin: 4.25rem; /*68px*/
    }
}

/*xl*/  
@media (min-width: 1280px) {
    :root {
        --Grid-Gap: 2.5rem; /*40px*/
        --Grid-Margin: 3rem; /*48px*/
    }
}
/* ------ << CSS VARIABLES ------ */
html,
body {
  background-color: #FEFEFE;
  padding: 0;
  -webkit-font-smoothing: antialiased;
}

body.grey {
  background-color: #F9F9F9;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

img,
picture,
video {
  display: block;
  max-width: 100%;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

ul,
ol {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.rawHtml-content * {
  margin: revert;
}

.rawHtml-content ul,
.rawHtml-content ol {
  padding: revert;
  list-style: revert;
}

.related-articles-rr * {
  margin: revert;
}

.related-articles-rr ul,
.related-articles-rr ol {
  padding: revert;
  list-style: revert;
}

.my-account * {
  margin: revert;
}

.my-account ul,
.my-account ol {
  padding: revert;
  list-style: revert;
}

.rawHtml-content-no-nativo * {
  margin: revert;
}

.rawHtml-content-no-nativo ul,
.rawHtml-content-no-nativo ol {
  padding: revert;
  list-style: revert;
}

@supports selector(:has(> *)) {
  .rawHtml-content *,
  .related-articles-rr *,
  .my-account *,
  .rawHtml-content-no-nativo * {
    margin: revert;

    ul,
    ol {
      padding: revert;
      list-style: revert;
    }
  }
}

/* ThirdPartiesReset */

.paywall-selector {
  position: relative !important;
  top: 10%;
  z-index: 1000;
}

@media (min-width: 528px) {
  .paywall-selector {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
}

.paywallActive {
  filter: grayscale(0.5) blur(10px);
  z-index: -1;
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.paywallFade::before {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  height: 300px;
  background-image: linear-gradient(
    to top,
    #FFFFFFFF,
    #FFFFFF00 50%
  );
  z-index: -1;
}

#ta-irm-form-renderer {
  position: fixed;
  z-index: 100001;
}

.paywallFade::before {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  height: 300px;
  background-image: linear-gradient(
    to top,
    #FFFFFFFF,
    #FFFFFF00 50%
  );
  z-index: -1;
}

#ta-irm-form-renderer {
  position: fixed;
  z-index: 100001;
}

@media (min-width: 1440px) {
  .subscribe-page .tp-container-inner iframe {
    max-width: none !important;
    width: 100% !important;
    margin-left: 0 !important;
  }
}

.tp-container-inner {
  max-width: none !important;
  width: 100% !important;
}

@media (max-width: 1440px) {
  .regwall-shadow {
    box-shadow: 0px 8px 20px 0px #0000001A;
  }
}

@media (min-width: 1440px) {
  .regwall-shadow iframe {
    box-shadow: 0px 8px 20px 0px #0000001A;
  }
}

.tp-iframe-wrapper.tp-active {
  max-width: 98vw !important;
}

.tp-modal .tp-close:focus {
  border: none !important;
}

.tp-modal .tp-close.tp-active {
  transform: scale(2) !important;
}

.tp-modal .tp-close {
  top: 10px !important;
  right: 10px !important;
  width: 15px !important;
  height: 15px !important;
  box-shadow: none !important;
}

@media (max-width: 425px) {
  .tp-iframe-wrapper.tp-active {
    max-width: unset !important;
  }

  .tp-iframe-wrapper {
    text-align: center;
    width: 100% !important;
  }
}

.tp-backdrop .tp-active {
  background: #111111;
  background-image: linear-gradient(180deg, #FFFFFFC0 0%, #FFFFFF 100%);
  opacity: 0.8;
}

.stn-video-player {
  aspect-ratio: 16 / 9;
  --stn-player-borderRadius: 0;
  --stn-player-progressBarTrackColor: #007b9d;
  --stn-player-volumeBarTrackColor: #007b9d;
  --stn-player-titleBarBackground: #eff8fa;
  --stn-player-titleBarColor: #111111;
  --stn-player-titleBarButtonsBackground: #007b9d;
  --stn-player-titleBarButtonsBackgroundHover: #007b9d;
  --stn-player-miniTitleBarButtonsBackground: #007b9d;
  --stn-player-stnEngageProgressColor: #007b9d;
}

